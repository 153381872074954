<template>
  <b-collapse tag="ul" :class="className" :visible="open" :id="idName" :accordion="accordianName">
    <template v-for="(item,index) in items">
      <!--
        !item.APP_ENV => handles the menu json for Admin where we have not set this condition
        item.APP_ENV =>  handles the menu json for GIDE where we set the condition to prod to denote that the menu needs to be visible on PROD
        VUE_APP_ENV =>  Menu is displayed on local & uat irrespective of above
      -->
      <li :title="item.name" @click="toogleSide(item)" :key="index" :class="item.is_heading ? 'iq-menu-title' :activeLink(item) && item.children ? 'active' : activeLink(item) ? 'active' : ''"
        v-if="
        (!item.APP_ENV || item.APP_ENV == 'prod' || VUE_APP_ENV != 'prod')
        && (!item.exludeUserRole || (item.exludeUserRole && !item.exludeUserRole.includes(userData.user_role)) )
        "
      >
        <i v-if="item.is_heading" class="ri-subtract-line" />
        <span v-if="item.is_heading">{{ $t(item.name) }}</span>
        <router-link :to="getLink(item)" v-if="!item.is_heading" :class="`${activeLink(item) && item.children ? 'active' : activeLink(item) ? 'active' : ''}`" v-b-toggle="item.name">
          <i :class="item.icon" v-if="item.is_icon_class"/>
          <template v-else>
          </template>
          <span>{{ $t(item.name) }}</span>
          <i v-if="item.children" class="ri-arrow-right-s-line iq-arrow-right" />
          <small v-html="item.append" :class="item.append_class" class="vueHtmlParent" />
        </router-link>
        <List v-if="item.children" :items="item.children" :open="item.link.name !== '' && activeLink(item) && item.children ? true : !!(item.link.name !== '' && activeLink(item))" :idName="item.name" :accordianName="`sidebar-accordion${item.class_name}`" :className="`iq-submenu ${item.class_name}`" />
      </li>
    </template>
  </b-collapse>
</template>
<script>
import List from "./ListStyle1"
import { socialvue } from "../../../config/pluginInit"
export default {
  name: "List",
  props: {
    items: Array,
    className: { type: String, default: "iq-menu" },
    horizontal: Boolean,
    open: { type: Boolean, default: false },
    idName: { type: String, default: "sidebar" },
    accordianName: { type: String, default: "sidebar" }
  },
  components: {
    List
  },
  data () {
    return {
      VUE_APP_ENV: process.env.VUE_APP_ENV
    }
  },
  computed: {
    userData () {
      return { ...this.$store.getters["User/userData"] }
    }
  },
  mounted () {
  },
  methods: {
    getLink (item) {
      // console.log(item.name, this.userData.user_role, "this.userData.modules =>", this.userData.modules)

      if (item.name === "Home" && this.userData.modules) {
        if (this.userData.modules.organisations && Object.keys(this.userData.modules.organisations).length > 0) {
          // Org
          if (Object.keys(this.userData.modules.organisations).length > 1) {
            return `/orgAccountDashboard`
          }
          else {
            // return `/organisation/${Object.keys(this.userData.modules.organisations)[0]}`
            let orgId = Object.keys(this.userData.modules.organisations)
            let orgType = this.userData.modules.organisations[orgId].type
            return `/organisations/${orgType}/${orgId}`
          }
        }
      }

      // Organisation SAAS Dashboard
      if (item.name === "Dashboard" && this.userData.modules && Object.keys(this.userData.modules).length > 0) {
        // since we are allocating some of the roles that were created under GIDE_ORG check if there are other orgs than GIDE_ORG
        const otherOrg = Object.keys(this.userData.modules.organisations).filter(e => e != "GIDE_ORG_1")

        // Navigating to dashboard only if the user has organisation other than GIDE_ORG
        if (otherOrg.length == 1 && this.userData.user_role == "USERROLE11116") {
          return `/dashboard`
        }
      }
      else if (item.name === "Follower List") {
        if (this.userData.modules.organisations && Object.keys(this.userData.modules.organisations).length > 0) {
          return `/followerReport/${Object.keys(this.userData.modules.organisations)}`
        }
      }
      else if (item.name === "My Profile") {
        return `/user/${this.userData.user_id}`
      }

      return item.link
    },
    activeLink (item) {
      return socialvue.getActiveLink(item, this.$route.name)
    },
    toogleSide (item) {
      // Keep the Left Side Bar open. It closes only if the width is less than 1500
      if (window.innerWidth < process.env.VUE_APP_LEFT_SIDE_BAR_KEEP_TOGGLE_PX) {
        if (item.children || (item.link && item.link.name && item.link.name.includes("admin"))) {
          return
        }
        socialvue.triggerSet()
      }
    }
  }
}
</script>
