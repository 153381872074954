<template>
  <!-- TOP Nav Bar -->
  <div class="iq-top-navbar" id="refresh_icon">
    <div class="iq-navbar-custom">
      <nav class="navbar navbar-expand-lg navbar-light p-0">
        <div class="iq-navbar-logo d-flex justify-content-between">
          <router-link to="/home">
            <img :src="logo" class="img-fluid navBarLogo" alt="logo">
          </router-link>
          <div class="iq-menu-bt align-self-center">
            <div class="wrapper-menu" @click="miniSidebar">
              <div class="main-circle">
                <i class="ri-menu-line"></i>
              </div>
            </div>
          </div>
        </div>
        <ul class="navbar-nav ml-auto navbar-list" v-if="userData">
        <!-- search user -->
          <li>
            <a class="search-toggle" href="#">
              <b-button class="chat_notification">
                <i class="ri-user-search-line pointer mr-2" style="font-size:25px" title="Search user"></i>
              </b-button>
            </a>
            <!-- Search Box -->
            <div class="iq-sub-dropdown iq-user-dropdown">
              <div class="iq-card shadow-none m-0">
                <div class="iq-card-body p-0 ">
                    <!-- Backend Search -->
                      <b-form-input class="form-control searchBox"
                        @input="searchAdminFaqWithParams"
                        v-model="whereFilter.search_param"
                        type="search"
                        :placeholder="cvSearchText">
                      </b-form-input>
                    <!-- Backend Search -->
                    <div class="invite_box input_search_box">
                     <div v-for="(userObj, index) of userObjList" :key="index" style="line-height: 50px;">
                      <a href="#" class="iq-sub-card" v-if="index < 5" @click="goToUserProfile(userObj.user_id)">
                        <div class="media align-items-center" style="position: relative;">
                          <div class="media-body search">
                            <b-avatar style="border-radius: 100%!important;" class="avatar-10 mr-2 rounded" v-if="userObj.image">
                              <img :src="userObj.image" class="avatar-10 rounded" alt="User Profile">
                            </b-avatar>
                             <b-avatar v-else class="avatar-10 mr-2" :text="getFirstLetterOfAString(userObj.user_name)" >
                            </b-avatar>
                            <h6 class="mb-0" v-if="userObj.user_name">{{userObj.user_name}}</h6>
                            <br>
                          </div>
                        </div>
                        <small class="pull-right">{{ userObj.user_city}}, {{userObj.user_state}}</small>
                        <hr class="p-0" style="margin-bottom: 0px;">
                      </a>
                    </div>
                  </div>
                  <div>
                    <button v-if="userObjList && userObjList.length > 5"  style="margin: auto; display: block;" class="btn btn-primary mb-2" @click="goToUserList()">
                      Show More...
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </li><!-- search Box -->

          <!-- Message Invites -->
          <li>
            <a v-if="invite_recieved && invite_recieved.length > 0" class="search-toggle" href="#">
              <b-button class="chat_notification">
                <i class="ri-message-3-fill pointer mr-2" style="font-size:25px" title="Join Messaging Invitations"></i>
                <sup style="color: #e5252c;">{{ invite_recieved.length }}</sup>
              </b-button>
            </a>
            <a v-else class="search-toggle" href="#">
              <b-button class="chat_notification">
                <i class="ri-message-3-line pointer primary-color mr-2" style="font-size:25px" title="Message"></i>
              </b-button>
            </a>

            <!-- Message Invites Dropdown -->
            <div id="mess_invite" class="iq-sub-dropdown iq-user-dropdown" v-if="userData">
              <div class="iq-card shadow-none m-0">
                <div class="iq-card-body p-0 ">
                  <div class="bg-primary p-3 line-height">
                    <h5 class="mb-0 text-white line-height" style="object-fit:contain">
                      Message Invites
                    </h5>
                  </div>
                  <div class="invite_box">
                    <div v-for="(invObj, index) of invite_recieved" :key="index">
                      <a href="#" class="iq-sub-card" v-if="index < 5">
                        <div class="media align-items-center">
                          <div class="">
                            <img v-if="invObj.group_icon" class="avatar-40 rounded" :src="invObj.group_icon" alt="">
                            <b-avatar v-else class="logo-icon" :variant="getBgColorForLetter(invObj.group_name)" :text="getFirstLetterOfAString(invObj.group_name)"></b-avatar>
                          </div>
                          <div class="media-body ml-3">
                            <h6 class="mb-0 ">{{invObj.group_name}}</h6>
                            <p class="mb-0">
                              <small style="color:#109618" class="pointer" @click="handleInvite(invObj, 0)">Accept</small>
                              <small style="color:#ff9900;margin:0.5rem;" class="pointer" @click="handleInvite(invObj, 3)">Reject</small>
                              <small style="color:#dc3912" class="pointer" @click="handleInvite(invObj, 1)">Block</small>
                            </p>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div><!-- Message Invites Dropdown -->
          </li><!-- Message Invites -->

          <!-- Notifications -->
          <li v-if="VUE_APP_ENV != 'prod'">
            <a v-if="adminNotificationObjList && adminNotificationObjList.length > 0" href="#" class="search-toggle">
              <b-button class="chat_notification">
                <i class="ri-notification-fill pointer mr-2" style="font-size:25px" title="Notifications"></i>
                <!-- <sup style="color: #e5252c;">*</sup> -->
              </b-button>
            </a>
            <i v-else class="ri-notification-line pointer primary-color mr-2" style="font-size:25px" title="Notifications"></i>

            <!-- Notifications Dropdown -->
            <div id="mess_notification" class="iq-sub-dropdown iq-user-dropdown" v-if="userData">
              <div class="iq-card shadow-none m-0">
                <div class="iq-card-body p-0 ">
                  <div class="bg-primary p-3 line-height">
                    <h5 class="mb-0 text-white line-height" style="object-fit:contain">
                      Notifications
                    </h5>
                  </div>
                  <div v-for="(notObj, index) of adminNotificationObjList" :key="index">
                    <a href="#" class="iq-sub-card pb-0" v-if="index < 3">
                      <div class="media align-items-center" style="position: relative;">
                        <ul class="media-body ml-0 bullet" style="padding-inline-start: 10px;">
                          <li class="mb-0 noti_list" style="list-style: none !important;"> {{notObj.notification_title}}
                            <span class="date mt-2">{{notObj.created_on | dateFormatDDMMYYYYSlash}}</span>
                          </li>
                        </ul>
                      </div><hr/>
                    </a>
                  </div>
                  <div>
                    <button v-if="adminNotificationObjList && adminNotificationObjList.length > 3" style="margin: auto; display: block;" class="btn btn-primary mb-2" @click="goToNotificationList()">
                      Show More...
                    </button>
                  </div>
                </div>
              </div>
            </div><!-- Notifications Dropdown -->
          </li><!-- Notifications -->

          <!-- FAQ -->
          <li v-if="VUE_APP_ENV != 'prod'">
            <a class="search-toggle" href="#">
              <b-button class="chat_notification">
                <i class="ri-question-line pointer mr-2" @click="goToFaq()" style="font-size:25px" title="FAQ"></i>
              </b-button>
            </a>
          </li><!-- FAQ -->

          <!-- Subscription Plan -->
          <li v-if="mySubsciptionPlan && current_ts/1000 <mySubsciptionPlan.subs_end_on">
            <router-link :to="`/my_billing_history/${userData.user_id}`">
              <span>{{mySubsciptionPlan.subs_plan_name}}</span>
            </router-link>
          </li>
          <li v-else-if="userData.user_role == 'USERROLE11114' && IS__MOBILE_DEVICE">
            <router-link to="/subscription_plans">
              <!-- This is a Mobile Device -->
              <span>Student Club</span>
            </router-link>
          </li>
          <li v-else-if="userData.user_role == 'USERROLE11114' && !IS__MOBILE_DEVICE">
            <router-link to="/subscription_plans">
              <!-- This is a Laptop or Computer -->
              <span>Become A Student Club Member</span>
            </router-link>
          </li><!-- Subscription Plan -->

          <!-- <li v-else-if="userData.user_role != 'USERROLE11111'">
            <router-link to="/subscription_plans">
              <span><small>Upgrade</small></span>
            </router-link>
          </li> -->
          <li v-if="userData.user_role == 'USERROLE11111' && !$router.currentRoute.path.includes('admin')">
            <router-link to="/admin/dashboard" >
              <span>Admin Panel</span>
            </router-link>
          </li>
          <li v-if="$router.currentRoute.path.includes('admin/')">
            <router-link to="/home">
              <span>Home</span>
            </router-link>
          </li>
          <li>
            <a href="#" id="toogleLogoPro" class="search-toggle d-flex justify-content-center align-items-center UserLogoNavBarA">
              <UserLogo :propSize="'avatar-50'" />
            </a>
            <div class="iq-sub-dropdown iq-user-dropdown" v-if="userData">
              <div class="iq-card shadow-none m-0">
                <div class="iq-card-body p-0 ">
                  <div class="bg-primary p-3 line-height">
                    <h5 class="mb-0 text-white line-height" style="object-fit:contain">
                      {{userData.user_name}}
                    </h5>
                    <span class="text-white font-size-12">{{userData.user_email}}</span><br/>
                    <span class="text-white font-size-12">
                      Id: {{userData.user_id}}&nbsp;&nbsp;
                      <i class="fa-solid fa-copy pointer" @click="doCopy(userData.user_id)"></i>
                    </span><br/>
                    <span class="text-white font-size-12">Role: {{getUserRole(userData.user_role)}}</span>
                  </div>
                  <router-link :to='`/user/${userData.user_id}`' class="iq-sub-card iq-bg-info-hover">
                    <div class="media align-items-center">
                      <div class="rounded iq-card-icon">
                        <i class="fa fa-solid fa-user"></i>
                      </div>
                      <div class="media-body ml-3">
                        <h6 class="mb-0 ">
                          My Profile
                        </h6>
                        <p class="mb-0 font-size-12">
                          View personal profile details.
                        </p>
                      </div>
                    </div>
                  </router-link>
                  <a @click="goToUserProfileEdit()" style="cursor: pointer" class="iq-sub-card iq-bg-info-hover">
                    <div class="media align-items-center">
                      <div class="rounded iq-card-icon">
                        <i class="fa-solid fa-user-circle"></i>
                      </div>
                      <div class="media-body ml-3">
                        <h6 class="mb-0 ">Edit Profile</h6>
                        <p class="mb-0 font-size-12">Modify your personal details.</p>
                      </div>
                    </div>
                  </a>
                  <a @click="goToResumeBuilder()" style="cursor: pointer" class="iq-sub-card iq-bg-info-hover">
                    <div class="media align-items-center">
                      <div class="rounded iq-card-icon">
                        <i class="ri-file-paper-line"></i>
                      </div>
                      <div class="media-body ml-3">
                        <h6 class="mb-0 ">Resume Builder <sup>Beta</sup></h6>
                        <p class="mb-0 font-size-12">Build Your Resume</p>
                      </div>
                    </div>
                  </a>
                  <router-link :to="`/my_billing_history/${userData.user_id}`" class="iq-sub-card iq-bg-info-hover">
                    <div class="media align-items-center">
                      <div class="rounded iq-card-icon">
                        <i class="fa-solid fa-credit-card"></i>
                      </div>
                      <div class="media-body ml-3">
                        <h6 class="mb-0 ">
                          Billing
                        </h6>
                        <p class="mb-0 font-size-12">
                          View your billing history
                        </p>
                      </div>
                    </div>
                  </router-link>
                  <div class="iq-sub-card iq-bg-info-hover" @click="deleteAccount">
                    <div class="media align-items-center">
                      <div class="rounded iq-card-icon">
                        <i class="fa-solid fa-trash"></i>
                      </div>
                      <div class="media-body ml-3">
                        <h6 class="mb-0 ">
                          Delete Account
                        </h6>
                        <p class="mb-0 font-size-12">
                          Delete your account
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="d-inline-block w-100 text-center p-3">
                    <a class="bg-primary iq-sign-btn" href="#" role="button" @click="signOut">Sign out<i class="ri-login-box-line ml-2"></i></a>
                    <div v-if="isLoggingOut" class="text-center mt-3">
                      <b-spinner variant="primary" label="Spinning"></b-spinner>
                    </div>
                  </div>
                  <div class="d-inline-block w-100 text-center p-3">
                    <span>{{getAppVersion()}}</span>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </nav>
    </div>
    <div  v-if="IS_APP" class="arrow_up" onClick="window.location.reload()">
      <i class="ri-arrow-left-line side-right-icon"></i>
    </div>
    <Otp v-if="showMobileOtpModal" :propOpenModal="showMobileOtpModal" @emitCloseOtpModal="closeOtpModal" :propShowCancelBtn="showOtpCancelBtn" />

    <AlertBox :key="showDeleteConfirmAlertBox" v-if="showDeleteConfirmAlertBox" :propAlertTitle="alertTitle" :propAlertDesc="alertDesc" :propOkBtnText="okText" :propCancelBtnText="cancelText" @emitCloseAlertModel="showDeleteConfirmAlertBox=false" @emitConfirmAlertModel="deleteAccountCompleted()"/>

    <b-toast
      v-model="showToast"
      name="Toast"
      :variant="toastVariant"
      :title="toastTitle">
      <div v-html="toastMsg">
      </div>
    </b-toast>
  </div><!-- TOP Nav Bar END -->
</template>

<script>
import UserLogo from "./UserLogo.vue"
import { mapGetters } from "vuex"
import { Subscriptions } from "../../../FackApi/api/Subscription/subscription"
import UserRoles from "../../../FackApi/json/UserRoles.json"
import Otp from "../../../components/User/Otp.vue"
// import { EventBus } from "../../../main.js"
import AlertBox from "../../../components/AlertBox.vue"
import { User } from "../../../FackApi/api/user.js"
import { MeshApi } from "../../../FackApi/api/MeshApi"
import { AdminNotifications } from "../../../FackApi/api/AdminNotification.js"
import Utility from "../../../Utils/utility"

export default {
  name: "NavBarStyle1",
  props: {
    homeURL: { type: Object, default: () => ({ name: "layout1.dashboard" }) },
    title: { type: String, default: "Dashboard" },
    logo: { type: String, default: require("../../../assets_gide/img/logo/gide_logo.png") },
    horizontal: { type: Boolean, default: false },
    items: { type: Array }
  },
  components: {
    UserLogo,
    Otp,
    AlertBox
  },
  computed: {
    ...mapGetters({
      bookmark: "Setting/bookmarkState"
    }),
    userData () {
      return { ...this.$store.getters["User/userData"] }
    }
  },
  data () {
    return {
      cvSearchText: "Type to Search",
      showInviteModal: false,
      globalSearch: "",
      showSearch: false,
      showMenu: false,
      vmSelectedUser: null,
      cvUserOptions: [],
      mySubsciptionPlan: null,
      showMobileOtpModal: false,
      VUE_APP_ENV: process.env.VUE_APP_ENV,
      showOtpCancelBtn: true,
      showDeleteConfirmAlertBox: false,
      okText: "Delete",
      cancelText: "Not now",
      alertDesc: "",
      alertTitle: "",
      toastTitle: "",
      toastMsg: "",
      showToast: false,
      IS_APP: window.__IS_APP__,
      IS__MOBILE_DEVICE: window.__IS__MOBILE_DEVICE__,
      toastVariant: "danger",
      current_ts: (new Date().getTime()),
      isLoggingOut: false,
      cvbtnModalCancel: "Close",
      userContactList: null,
      invite_recieved: [],
      adminNotificationObjList: null,
      userObjList: [],
      whereFilter: {
        search_param: ""
      }
    }
  },
  watch: {
    "$route.path" (newPath) {
      if (newPath === "/subscription_plans") {
        this.loadRequiredData()
      }
      else {
        /** Hide Otp modal on page change */
        this.showMobileOtpModal = false
        this.showOtpCancelBtn = true
      }
    }
  },
  async mounted () {
    document.addEventListener("click", this.closeSearch, true)
    this.loadRequiredData()
    this.userSubscription()
    this.adminNotificationList()
    this.getMeshChatInvites()
  },
  methods: {
    /**
    * searchAdminFaqWithParams
    */
    async searchAdminFaqWithParams () {
      if (this.whereFilter.search_param.length <= 3) {
        return
      }
      this.userList()
    },
    /**
    * userList
    */
    async userList () {
      try {
        let userListResp = await User.userListSearch(this, this.whereFilter)
        if (userListResp.resp_status) {
          this.userObjList = userListResp.resp_data.data
          this.totalRows = userListResp.resp_data.count
        }
        else {
          this.userObjList = []
          this.toastMsg = userListResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
      catch (err) {
        console.error("Exception occurred at userList() and Exception:", err.message)
      }
    },
    /**
     * Redirect to FAQ
     */
    goToFaq () {
      try {
        this.$router.push("/faq_list")
      }
      catch (err) {
        console.error("Exception occurred at FAQ List() and Exception:", err.message)
      }
    },
    /**
     * Go To User Profile
     */
    goToUserProfile (userId) {
      this.$router.push(`/user/${userId}`)
    },
    /**
     * User List
     */
    goToUserList (userId) {
      try {
        if (this.whereFilter.search_param) {
          this.$router.push(`/user_search/${this.whereFilter.search_param}`)
        }
        else {
          this.$router.push(`/user_search/${userId}`)
        }
      }
      catch (err) {
        console.error("Exception occurred at User Search() and Exception:", err.message)
      }
    },
    /**
     * getBgColorForLetter
     */
    getBgColorForLetter (name) {
      let firstLetter = this.getFirstLetterOfAString(name)
      return Utility.getBgColorForLetter(this, firstLetter)
    },
    /**
     * onRefresh
     * Reload the App
     */
    onRefresh () {
      window.location.reload()
    },
    /**
     * getAppVersion
     */
    getAppVersion () {
      return process.env.VUE_APP_VERSION
    },
    /**
     * loadRequiredData
     */
    async loadRequiredData () {
      if (!this.userData.user_mobile_verified) {
        this.userData.user_mobile_verified = 0
      }

      if (this.$route.path === "/subscription_plans") {
        this.showOtpCancelBtn = false
      }
      else {
        this.showOtpCancelBtn = true
      }

      if (this.userData &&
        Number(this.userData.user_mobile_verified) !== 1 &&
        (this.VUE_APP_ENV === "prod" || this.VUE_APP_ENV !== "uat")
      ) {
        this.showMobileOtpModal = true
      }
    },
    /**
     * handleInvite
     */
    async handleInvite (group, action) {
      /**
       * 0 => publish/activate the grp
       * 1 => soft delete the group/block the user
       * 3 => hard delete the
       */
      const postData = {}

      if (action != 3) {
        postData.group_id = group.group_id
        postData.chat_user_id = group.created_By
        postData.deleted = action
        postData.invite_page = JSON.stringify(true)

        const grpEditResp = await MeshApi.MeshGroupEdit(this, postData)
        if (!grpEditResp.resp_status) {
          this.toastMsg = grpEditResp.resp_code
          this.toastVariant = "danger"
          this.showToast = true
          return
        }
      }
      else {
        postData.group_id = group.group_id
        postData.chat_user_id = group.created_By
        postData.invite_page = true

        const grpDeleteResp = await MeshApi.MeshGroupDelete(this, postData)
        if (!grpDeleteResp.resp_status) {
          this.toastMsg = grpDeleteResp.resp_code
          this.toastVariant = "danger"
          this.showToast = true
          return
        }
      }
      this.invite_recieved = this.invite_recieved.filter(grp => grp.group_id != group.group_id)
    },
    /**
     * openInviteModal
     */
    openInviteModal () {
      this.showInviteModal = !this.showInviteModal
    },
    /**
     * closeOtpModal
     */
    closeOtpModal () {
      this.showMobileOtpModal = false
      // EventBus.$emit("openUserInstrest", true)
    },
    /**
     *  Do Copy
     */
    doCopy: function (msg) {
      var dummy = document.createElement("textarea")

      document.body.appendChild(dummy)
      dummy.value = msg
      dummy.select()
      document.execCommand("copy")
      document.body.removeChild(dummy)
    },
    /**
     * getUserRole
     * */
    getUserRole (role) {
      return UserRoles[role]
    },
    /**
    * userSubscription
    * */
    async userSubscription () {
      const MyPlan = await Subscriptions.userSubscription(this)
      if (!MyPlan) {
        return
      }

      if (!MyPlan.resp_status) {
        return
      }

      this.mySubsciptionPlan = MyPlan.resp_data.data
      this.$store.commit("Paygw/addMyPlan", this.mySubsciptionPlan)
    },
    miniSidebar () {
      // Keep the Left Side Bar open. It closes only if the width is less than 1500px
      if (window.innerWidth < process.env.VUE_APP_LEFT_SIDE_BAR_KEEP_TOGGLE_PX) {
        this.$emit("toggle")
      }
    },
    openSearch () {
      this.showSearch = true
      this.showMenu = "iq-show"
      this.globalSearch = ""
    },
    closeSearch (event) {
      let classList = event.target.classList
      if (!classList.contains("searchbox") && !classList.contains("search-input")) {
        this.removeClass()
      }
    },
    removeClass () {
      this.showSearch = false
      this.showMenu = ""
      this.globalSearch = ""
    },
    /**
     * getFirstLetterOfAString
     */
    getFirstLetterOfAString (string) {
      if (string) {
        return string.substr(0, 1)
      }
    },
    /**
     * meshLogout
     */
    async meshLogout () {
      // Logging out oof mesh via iframe by redirecting to /mesh_logout
      let meshUrl = process.env.VUE_APP_MESH_SERVER_URL + `/mesh_logout`
      this.$store.dispatch("MeshUrl/setMeshUrl", meshUrl)

      await new Promise(resolve => {
        setTimeout(() => {
          resolve()
        }, 3000)
      })
    },
    /**
     * signOut
     */
    async signOut () {
      try {
        this.isLoggingOut = true
        await this.meshLogout()
        await this.$gAuth.signOut()
        await this.$store.commit("User/setUserLogout")
      }
      catch (err) {
        console.error("Exception occurred at signout() and Exception:", err.message)
      }

      setTimeout(async () => {
        await this.$router.push("/auth/login")
        this.isLoggingOut = false
        window.location.reload()
      }, 200)
    },
    /**
     * deleteAccount
     */
    async deleteAccount () {
      this.showDeleteConfirmAlertBox = true
      this.alertTitle = "Delete Account Confirmation"
      this.alertDesc = "With this action all your posts, comments, profile information, payment invoices, reports etc will be deleted. Are you sure you want to delete your account ?"
    },
    /**
     * deleteAccountCompleted
     */
    async deleteAccountCompleted () {
      this.showDeleteConfirmAlertBox = false

      // Fire the Delete Account API & Logout
      let userDeleteResp = await User.userDelete(this, this.userData.user_id)

      if (!userDeleteResp.resp_status) {
        this.toastTitle = "Account Deletion"
        this.toastMsg = "There was some issue deleting your account. Please write to " + process.env.VUE_APP_SUPPORT_EMAIL
        this.showToast = true
        this.toastVariant = "danger"
      }
      else {
        this.toastTitle = "Account Deleted"
        this.toastMsg = "Account Deletion was success. For restoring it back, please send email to hey@gide.ai from your registered email id within 90 days of deletion"
        this.showToast = true
        this.toastVariant = "success"

        setTimeout(() => {
          this.signOut()
        }, 3000)
      }
    },
    /**
     * goToUserProfileEdit
     */
    goToUserProfileEdit () {
      if (this.$router.currentRoute.path.includes(`user_edit/${this.userData.user_id}`)) {
        return
      }
      if (this.userData.user_id) {
        this.$router.push(`/user_edit/${this.userData.user_id}`)
      }
    },
    /**
     * goToResumeBuilder
     */
    goToResumeBuilder () {
      this.$router.push(`/resume_builder`)
    },
    /**
     * Notification list
     */
    goToNotificationList () {
      this.$router.push(`/admin_notification_list`)
    },
    /**
     * getMeshChatInvites
     */
    async getMeshChatInvites () {
      try {
        await this.getMeshUserContactList()

        const getInviteObj = {
          chat_user_id: this.userData.user_id,
          invite_page: true
        }
        const meshChatInviteResp = await MeshApi.MeshGroupList(this, getInviteObj)

        if (meshChatInviteResp.resp_status) {
          this.invite_recieved = meshChatInviteResp.resp_data
          this.group_participants = meshChatInviteResp.resp_data_2

          for (let group of this.invite_recieved) {
            if (group.type == 0) {
              /* 1-to-1 Message */

              if (group.participants && group.participants != null) {
                let participantIds = group.participants.split(",")

                participantIds.forEach((participantId) => {
                  if (participantId !== this.userData.user_id) {
                    let participant = this.group_participants.find((participant) => { return participant.participant_id === participantId })

                    if (participant) {
                      group.group_name = (participant.participant_name) ? participant.participant_name : "User X"

                      var usr = {
                        user_id: participant.participantId,
                        user_name: participant.participant_name,
                        image: participant.participant_image
                      }
                      group.group_icon = this.get_user_icon(usr)
                    }
                    else {
                      group.group_name = "User X"
                      group.group_icon = ""
                    }
                  }
                }) // End of forEach
              }
            }
          } // End of for
        } // end of if meshChatInviteResp
      }
      catch (err) {
        console.error("Exception in getMeshChatInvites and err: ", err)
      }
    },
    /**
     * getMeshUserContactList
     */
    async getMeshUserContactList () {
      try {
        const meshUserContactObj = {
          chat_user_id: this.userData.user_id
        }

        let myContactList = {}

        const meshUserContactResp = await MeshApi.MeshUserContactList(this, meshUserContactObj)
        if (meshUserContactResp.resp_status) {
          let userContactList = meshUserContactResp.resp_data

          userContactList.forEach((contact) => {
            var contactImageBase64 = null

            myContactList[contact.contact_id] = {
              user_id: contact.contact_id,
              user_name: contact.user_name,
              image: contact.image,
              image_checksum: contact.image_checksum,
              user_image_base64: contactImageBase64
            }
          })

          this.userContactList = userContactList
        }
      }
      catch (err) {
        console.error("Exception in getMeshUserContactList and err: ", err)
      }
    },
    /*
    * get_user_icon
    * */
    get_user_icon (user) {
      var userIcon = Utility.get_user_icon(user, this.userContactList)
      return userIcon
    },
    /**
     * adminNotificationList
     */
    async adminNotificationList () {
      try {
        let whereFilter = { "delete": "NAV_BAR_NOTIFICATION" }
        let adminNotificationListResp = await AdminNotifications.adminNotificationList(this, whereFilter)
        if (adminNotificationListResp.resp_status) {
          this.adminNotificationObjList = adminNotificationListResp.resp_data.data
        }
      }
      catch (err) {
        console.error("Exception occurred at Navbar adminNotificationList() and Exception:", err)
      }
    }
  }
}
</script>

<style lang="scss">
  .navbar-list li:last-child > a {
    padding-right: 0px !important;
    .logoSpan {
      width: 50px;
    }
  }
  #refresh_icon{
    // position: relative;
    width: 100%;
    .arrow_up{
      position: absolute;
      left: 0px;
      top: 0px;
      background: transparent;
      border-bottom: 12px solid #e4252d;
      border-left: 25px solid transparent;
      border-right: 25px solid transparent;
      height: 0;
      width: 100px;
      rotate: 180deg;
      right: 0;
      margin: auto;
      i{
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        color: #fff;
        width: 14px;
        top: -30px;
        rotate: 90deg;
      }
    }
  }
  .chat_notification{
    background-color: transparent !important;
    color: #e5252c;
    background: transparent !important;
    border: transparent;
    padding: 0px !important;
  }
  .chat_notification i{
    color: #e5252c;
  }
  .noti_list{
    width: 100%;
  }
  .noti_list .date{
    font-size: 11px;
    float:right ;
  }
  .noti_list::marker {
    color: #e5252c;
  }
  .notification_icon{
    position: relative;
    top: 3px;
  }
  @media (max-width: 667px){
  .iq-top-navbar #mess_invite.iq-sub-dropdown.iq-user-dropdown {
      right: 0px;
      left: -50px !important;
    }
    .iq-top-navbar #mess_notification.iq-sub-dropdown.iq-user-dropdown {
      right: 0px;
      left: 10px !important;
    }
  }
  #mess_notification{
    width: 230px;
  }
  #mess_invite{
    width: 230px;
  }
  .invite_box{
    min-height: 300px;
    max-height: 350px;
    overflow-y: auto;
  }
  .bullet::before{
    content: "";
    display: inline-flex;
    width: 5px;
    height: 5px;
    background-color: #e4252d;
    position: absolute;
    border-radius: 20px;
    top: 6px;
    z-index: 9999;
    left: -2px;
  }
  .search{
    display: inline-flex;
  }
  .navbar-nav.navbar-list li a.search-toggle{
    padding: 0 5px;
  }
  .searchBox{
    margin: 10px auto;
    width: 90%;
    border: 1px solid;
  }
  .avatar-10{
    width: 25px;
    height: 25px;
  }
  .navbar-list .input_search_box .iq-sub-card{
    padding: 5px 15px;
  }
</style>
